import { vennFlowApi,onboardingApi } from 'api/api';
import {   CLientLoadStateData, GetMarketingOnlyDetailRequest, GetMarketingOverviewRequest, MarketingOverviewData, Overview, OverviewDetail, loadDataRequest } from 'types/marketing/overview';

const getMarketingOverviewData = (params: GetMarketingOverviewRequest) =>
vennFlowApi.get<{ marketing_overview: Overview }>('/MarketingOnlyOverview/marketingOnlyOverview', { params });

// GetMarketingOnlyDetail code
const getMarketingOnlyDetailData = (params: GetMarketingOnlyDetailRequest) =>
vennFlowApi.get<{ marketing_detail: OverviewDetail }>('/MarketingOnlyDetailView/DetailView', { params });

const getPriorityLandingPageData = (params: GetMarketingOverviewRequest) =>
  vennFlowApi.get<{ marketing_overview: Overview }>('/MarketingOnlyOverview/priorityLandingPageData', { params });

//  vennFlowApi.get<{ overview: MarketingOverviewData }>('/MarketingOnlyOverview/marketingOnlyOverview', { params });


  //Overview

  const getCLientloadStateData = (params: loadDataRequest) =>
  onboardingApi.get<{result:CLientLoadStateData}>('/Companies/GetClientLoadState', { params });



  //CLientLoadState
export const marketingoverviewApi = {
    getMarketingOverviewData,
    getCLientloadStateData
};

export const marketingonlydetailApi = {
  getMarketingOnlyDetailData
};
